import PageHead from "./Head";

import Context from "@/context/Context";
import Store from "@/redux/store";
import { Provider } from "react-redux";

import Separator from "@/components/Common/Separator";
import FooterOne from "@/components/Footer/Footer-One";
import HeaderBasic from "@/components/Header/HeaderStyle-Four";
import NotFound from "@/components/NotFound/NotFound";

const ErrorPage = () => {
  return (
    <Provider store={Store}>
      <Context>
        <PageHead title="Colegio Menfis - Page not found" />
        <HeaderBasic headerSticky="rbt-sticky" headerType="" />

        <NotFound />

        <Separator />
        <FooterOne />
      </Context>
    </Provider>
  );
};

export default ErrorPage;
